import { useNavigate } from '@solidjs/router'
import { Component } from 'solid-js'
import Button from '~/components/button/button'
import styles from '~/components/not-found/not-found.module.scss'
import { translations } from '~/translations'

const NotFound: Component = () => {
  const navigate = useNavigate()

  const onClick = () => {
    navigate('/')
  }

  return (
    <div class={styles.notFound}>
      <div class={styles.title}>
        {translations().errors.notFound.title}
      </div>
      <p class={styles.description}>
        {translations().errors.notFound.description}
      </p>
      <Button 
        onClick={onClick}
        style='lagoon'
      >
        {translations().errors.notFound.back}
      </Button>
    </div>
  )
}

export default NotFound
