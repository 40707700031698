import { Title } from '@solidjs/meta'
import { HttpStatusCode } from '@solidjs/start'
import Landing from '~/components/landing/landing'
import NotFound from '~/components/not-found/not-found'

export default function NotFoundPage() {
  return (
    <Landing>
      <Title>Not Found</Title>
      <HttpStatusCode code={404} />
      <NotFound />
    </Landing>
  )
}
